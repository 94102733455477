import React, { Component } from 'react';

class Footer extends Component {
    
    ///////////////////////////
    // componentDidMount()
    ///////////////////////////
    componentDidMount() { 
        //console.log('Footer.componentDidMount()');
    } 

    
    ///////////////////////////
    // render()
    ///////////////////////////
    render() { 

        return (
            <footer className="footer bg-dark">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-6">
                            <p className="copyright font-alt">&copy; {(new Date().getFullYear())}&nbsp;<a href="/">Instant App</a>, All Rights Reserved</p>
                        </div>
                        <div className="col-sm-6">
                            <div className="footer-social-links"><a href="https://www.facebook.com/instantapp.dev"><i className="fa fa-facebook"></i></a><a href="#"><i className="fa fa-twitter"></i></a><a href="#"><i className="fa fa-dribbble"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        );
    }
}

export default Footer;
